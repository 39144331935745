import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	width: 100%;

	.select-container {
		position: relative;

		.value-container {
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			height: 40px;
			padding: 0.5em 1em;
			border-radius: 5px;
			box-shadow: 0px 0px 6px #00000029;
			color: #575757;

			.label {
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;

				.label-title-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 2em;
				}
			}

			.icon-container {
				color: #ba9449;

				svg {
					width: 16px;
					height: 28px;
				}

				.arrow-down-icon {
					transition: transform 0.3s ease-in-out;

					&.rotate {
						transform: rotate(180deg);
					}
				}
			}

			.checked-icon-wrapper {
				margin-top: 0.3em;
				margin-right: 0.5em;

				svg {
					width: 1em;
				}
			}

			&.has-value {
				background: transparent
					linear-gradient(275deg, #b9944a 0%, #c5a971 52%, #d0bc94 100%) 0% 0%
					no-repeat padding-box;

				.label {
					color: white;
				}

				.checked-icon-wrapper,
				.icon-container {
					filter: invert();

					& > * {
						filter: invert() brightness(0);
					}
				}
			}
		}

		.options-wrapper {
			overflow: hidden;
			position: absolute;
			top: 2em;
			width: 100%;
			background: white;
			z-index: 10;
			box-shadow: 0px 4px 6px #00000029;
			cursor: default;
			max-height: 320px;

			border-bottom-right-radius: 0.5em;
			border-bottom-left-radius: 0.5em;

			&.direction-top {
				top: unset;
				bottom: calc(100% + 10px);
				position: absolute;
			}
		}

		.options {
			overflow-y: auto;
			max-height: 280px;
			padding: 0.5em 1em;

			&::-webkit-scrollbar-track {
				border-radius: 6px;
				background-color: rgba(255, 255, 255, 0);
				box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 10%);
			}

			&::-webkit-scrollbar {
				width: 8px;
				box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 10%);
				border-radius: 6px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background-color: #ba9449;
			}

			.option {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				user-select: none;
				cursor: pointer;
				height: 30px;
				border-bottom: 1px solid #e0e0e0;
				padding: 20px 5px;

				&:after {
					position: absolute;
					content: '';
					width: 104%;
					left: -2%;
					border-radius: 5px;
					height: 80%;
					top: 10%;
					background: transparent
						linear-gradient(275deg, #b9944a 0%, #c5a971 52%, #d0bc94 100%) 0% 0%
						no-repeat padding-box;
					z-index: -1;
					opacity: 0;
					transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
				}

				&:last-of-type {
					border-bottom: none;
				}

				.label {
					width: 100%;
					font-size: 15px;
					display: flex;
					align-items: center;
					justify-content: flex-start;

					.label-title {
						font-weight: 700;
						width: 130px;
					}

					.subheadline {
						font-size: 13px;
						color: #b9944a;
					}
				}

				&.option-highlight {
					&:after {
						background: #bcc7ab30;
						opacity: 1;
					}

					&:hover:after {
						background: transparent
							linear-gradient(275deg, #b9944a 0%, #c5a971 52%, #d0bc94 100%) 0%
							0% no-repeat padding-box;
						opacity: 1;
					}
				}

				&.option-active {
					color: white;

					.label {
						.subheadline {
							color: ${variables.colorWhite};
						}
					}

					&:after {
						background: transparent
							linear-gradient(275deg, #b9944a 0%, #c5a971 52%, #d0bc94 100%) 0%
							0% no-repeat padding-box;
						opacity: 1;
					}

					&:hover:after {
						background: #cfb799;
						opacity: 1;
					}
				}

				@media (pointer: fine) {
					&:hover {
						color: white;

						.label {
							.subheadline {
								color: #ffffff;
							}
						}

						&:after {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media all and (max-width: ${variables.tabletL}) {
		.select-container {
			.value-container {
				.label {
					font-size: 12px;
				}
			}
		}
	}

	@media all and (max-width: ${variables.tabletS}) {
		.select-container {
			.value-container {
				height: 36px;

				.icon-container {
					color: #ba9449;
					margin-top: -0.2em;
				}
			}
		}
	}

	@media all and (max-width: ${variables.mobileL}) {
		.select-container {
			.options-wrapper {
				top: 0.5em;
				position: relative;
				max-height: 280px;
				padding: unset;

				.options {
					max-height: 220px;

					&::-webkit-scrollbar {
						width: 5px !important;
					}

					.option {
						height: 50px;

						.label {
							gap: 2px;
						}
					}
				}
			}
		}
	}

	@media all and (max-width: ${variables.mobileM}) {
		.label-mobile {
			display: none;
		}
	}
`;
