import React, { FunctionComponent, useContext, useState } from 'react';
import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Option } from 'types/options';

import DeviceContext from 'components/context/Device';
import LocaleContext from 'components/context/Locale';
import useOnClickOutside from 'hooks/useOnClickOutside';

import StyledComponent from './styles';
import { Props } from './types';

// TODO: Move to products module
const PublicLayoutProductSelect: FunctionComponent<Props> = ({
    defaultOption,
    options,
    placeholder,
    onChange,
    selectOptions,
    className,
}) => {
    const [collapsed, setCollapsed] = useState<Boolean>(true);
    const [dropDownTopDirection, setDropDownTopDirection] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<any>(defaultOption || { value: null, label: placeholder });

    const deviceContext = useContext(DeviceContext);
    const localeContext = useContext(LocaleContext);
    const translations = localeContext?.translations?.componentProductSelect;

    const handleOnOptionClick = (e, opt: Option<any>) => {
        if (opt.value === selectedOption.value && selectOptions?.deselect) {
            onChange(null);
            return setSelectedOption({ value: null, label: placeholder });
        }

        setSelectedOption(opt);
        setCollapsed(true);

        if (onChange) onChange(opt);
    };

    const handlerSetCollapsed = (e) => {
        setCollapsed(!collapsed);
        if (deviceContext.isMobile && e.target) {
            setDropDownTopDirection(e.target.getBoundingClientRect().top > 340);
        }
    };

    const selectContainerRef = useOnClickOutside<HTMLDivElement>({
        handler: () => {
            setCollapsed(true);
        },
    });

    return (
        <StyledComponent className={classnames('public-layout-product-select', className)}>
            <div
                className="select-container"
                ref={selectContainerRef}
            >
                <div
                    onClick={handlerSetCollapsed}
                    className={classnames(
                        'value-container',
                        { 'has-value': Boolean(selectedOption.value) },
                    )}
                >
                    <div className="label">
                        {selectedOption?.value && (
                            <span className="checked-icon-wrapper">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="checked-icon"
                                />
                            </span>
                        )}

                        <div className="label-title-container">
                            <span className="label-title">
                                {selectedOption?.label || translations.placeholder}
                            </span>

                            {(selectedOption.perProcedure && selectedOption.value !== 1) && (
                                <span className="subheadline">
                                    {`${selectedOption.priceDisplay} ${translations?.perProcedureSuffix}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="icon-container">
                        <FontAwesomeIcon
                            icon={faCaretDown}
                            className={classnames('arrow-down-icon', {
                                'rotate': !collapsed,
                            })}
                        />
                    </div>
                </div>
                {!collapsed && (
                    <div className={classnames(
                        'options-wrapper',
                        { 'direction-top': dropDownTopDirection }
                    )}
                    >
                        <div className="options">
                            {options.map((option, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        className={classnames(
                                            'option',
                                            {
                                                'option-active': option.value === selectedOption.value,
                                                'option-highlight': index === options.length - 1,
                                            }
                                        )}
                                        key={index}
                                        onClick={(e) => handleOnOptionClick(e, option)}
                                    >
                                        <div className="label">
                                            <span className="label-title">
                                                {option.label}
                                            </span>

                                            {(option.perProcedure && option.value !== 1) && (
                                                <span className="subheadline">
                                                    {`${option.priceDisplay} ${translations?.perProcedureSuffix}`}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutProductSelect;
